import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import $ from 'jquery'

import { setCurrentLanguage } from '../../actions/appAction';

import {
    translate
} from 'react-switch-lang';

export class Header extends Component {

    toggleHeader = () => {
        $('.navbar-toggler').click();
        this.props.toggleTermsAndConditions();
    }

    closeTerms = () => {
        this.props.closeTermsAndConditions();
    }

    render() {

        const { isMobileView } = this.props;
        const { t, currentLanguage } = this.props;

        const APP_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_APP_URL : process.env.REACT_APP_DEV_APP_URL
        const ADMIN_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_ADMIN_URL : process.env.REACT_APP_DEV_ADMIN_URL

        if(isMobileView){
            return (
                <nav className="navbar navbar-expand-lg fixed-top" id="navbarHeader">
                    <a className="navbar-brand" href="#"><img style={{ width : "138px" }} src={ require('../../images/tarjemle-logo.png') } /></a>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="top-bar line"></span> 
                        <span className="middle-bar line"></span> 
                        <span className="bottom-bar line"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li onClick={this.toggleHeader} style={{ marginLeft : "0", width : "100%"}} className="header-nav-item nav-item active">
                                <a style={{ textAlign : currentLanguage === "ar" ? "right" : "left" }} className="header-nav-link nav-link" href="#corporate">{t('nav-header.corporate')}</a>
                            </li>
                            <li onClick={this.toggleHeader} style={{ marginLeft : "0", width : "100%"}} className="header-nav-item nav-item">
                                <a style={{ textAlign : currentLanguage === "ar" ? "right" : "left" }} className="header-nav-link nav-link" href="#about-us">{t('nav-header.about-us')}</a>
                            </li>
                            <li onClick={this.toggleHeader} style={{ marginLeft : "0", width : "100%"}} className="header-nav-item nav-item">
                                <a style={{ textAlign : currentLanguage === "ar" ? "right" : "left" }} className="header-nav-link nav-link" href="#reviews">{t('nav-header.reviews')}</a>
                            </li>
                            <li className="header-nav-item nav-item header-nav-btn" style={{ paddingTop: "5px", marginTop : "85px", marginLeft : "0", width : "100%" }}>
                                <div className="row">
                                    {/* <div className="col">
                                        <a className="btn-link signup nav-link" href="https://app.tarjemle.com/signup">{t('nav-header.sign-up')}</a>
                                    </div>
                                    <div className="col">
                                        <a className="btn-link login nav-link" href="https://app.tarjemle.com">{t('nav-header.login')}</a>
                                    </div>       */}
                                     <div className="col">
                                        <a className="btn-link login nav-link" href={`${APP_URL}/?lang=${currentLanguage}`}>{t('nav-header.goto-app')}</a>
                                    </div>
                                </div>
                               
                            </li>
                            <li style={{ width : "100%", textAlign : "center" }} className="header-nav-item nav-item">
                                <div style={{ flex : "1", marginTop : "10px" }}>
                                    <img onClick={ () => this.props.setCurrentLanguage('ar')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                    <img onClick={ () => this.props.setCurrentLanguage('en')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            )
        }else{
            return (
                <nav className="navbar navbar-expand-lg fixed-top" id="navbarHeader">
                    <a className="navbar-brand" href="#"><img style={{ width : "138px" }} src={ require('../../images/tarjemle-logo.png') } /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="top-bar line"></span> 
                        <span className="middle-bar line"></span> 
                        <span className="bottom-bar line"></span>
                    </button>
                    <div className="collapse navbar-collapse navbar-dark" id="navbarNav">
                        <ul className={`navbar-nav ${currentLanguage==="ar"?"mr-auto":"ml-auto"}`}>
                            <li onClick={this.closeTerms} className="header-nav-item nav-item active">
                                <a className="header-nav-link nav-link" href="#home">{t('nav-header.corporate')}</a>
                            </li>
                            <li onClick={this.closeTerms} className="header-nav-item nav-item">
                                <a className="header-nav-link nav-link" href="#about-us">{t('nav-header.about-us')}</a>
                            </li>
                            <li onClick={this.closeTerms} className="header-nav-item nav-item">
                                <a className="header-nav-link nav-link" href="#reviews">{t('nav-header.reviews')}</a>
                            </li>
                            {/* <li onClick={this.closeTerms} className="header-nav-item nav-item header-nav-btn" style={{ paddingTop: "5px" }}>
                                <a className="btn-link login nav-link" href="https://app.tarjemle.com">{t('nav-header.login')}</a>
                            </li>
                            <li onClick={this.closeTerms} className="header-nav-item nav-item header-nav-btn" id="btn-signup" style={{ paddingTop: "5px", marginLeft : currentLanguage === "ar" ? "0" : "40px", marginRight : currentLanguage === "ar" ? "20px" : "0" }}>
                                <a className="btn-link signup nav-link" href="https://app.tarjemle.com/signup">{t('nav-header.sign-up')}</a>
                            </li> */}
                            <li onClick={this.closeTerms} className="header-nav-item nav-item header-nav-btn" id="btn-signup" style={{ paddingTop: "5px", marginLeft : currentLanguage === "ar" ? "0" : "40px", marginRight : currentLanguage === "ar" ? "20px" : "0" }}>
                                <a className="btn-link signup nav-link"  href={`${APP_URL}/?lang=${currentLanguage}`}>{t('nav-header.goto-app')}</a>
                            </li>
                            <li style={ currentLanguage === "ar" ? { marginRight : "15px" } : { marginLeft : "15px" }} className="header-nav-item nav-item">
                                <div style={{ flex : "1", marginTop : "10px" }}>
                                    <img onClick={ () => this.props.setCurrentLanguage('ar')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                    <img onClick={ () => this.props.setCurrentLanguage('en')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            )
        }

        
    }
}

const mapStateToProps = (state) => {
    return {
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        setCurrentLanguage
    })
)(Header)