import React, { Component } from 'react'
import UserRating from './UserRating'

export class CustomerReview extends Component {

    componentDidUpdate(){
        return false;
    }

    render() {
        const { review } = this.props;

        return (
            <div className="review">
                <img src={ review.user_photo } className="review-user-photo" />
                <p className="review-user-name">{ review.user }</p>
                <p className="review-user-type">{ review.user_type }</p>
                <p className="review-user-content">{ review.content }</p>
                <UserRating rating={ review.rating}/>
            </div>
        )

    }

}

export default CustomerReview;