export const VERIFY_EMAIL_ADDRESS = "VERIFY_EMAIL_ADDRESS"
export const VERIFY_EMAIL_ADDRESS_SUCCESS = "VERIFY_EMAIL_ADDRESS_SUCCESS"
export const VERIFY_EMAIL_ADDRESS_ERROR = "VERIFY_EMAIL_ADDRESS_ERROR"


export const ADD_MODAL = "ADD_MODAL"
export const POP_MODAL = "POP_MODAL"
export const SET_HEADER = "SET_HEADER"
export const SET_DEVICE = "SET_DEVICE" 

export const SET_CURR_LANG = "SET_CURR_LANG"