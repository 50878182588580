import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../actions/appAction';

export class Cookie extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#cookieModal').modal({backdrop:'static', keyboard:false});
            $('#cookieModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#cookieModal').modal('show');
            this.props.addModal('cookie-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("c-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="cookie-modal");
            if(modalInstance !== undefined){
                $('.c-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#cookieModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }

    render(){
        
        const { modalZ } = this.state

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} cookie-modal modal fade modal-opaque`} id="cookieModal" tabIndex="-1" role="dialog" aria-labelledby="cookieModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col align-right">
                                        <button onClick={this.props.closeCookieModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header" style={{ borderBottom : "unset" }}>
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col align-right">
                                        <button onClick={this.props.closeCookieModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }    
                        <div className="modal-body">
                            <div>
                                <div style={{ flex : "1", textAlign : "center" }}>
                                    <p style={{ fontWeight : "bold", color : "#414042", fontSize : "32px" }}>{t('cookie_header')}</p>
                                    <p style={{ fontWeight : "500", color : "#414042", fontSize : "16px" }}>{t('cookie_content')}</p>
                                </div>
                                <div style={{ flex: "1", marginTop : "50px", textAlign: "center" }}>
                                    <img src={require('../../images/cookies.png')} style={{ width : "280px", height : "212px" }} />
                                </div>
                                {
                                    isMobileView ?
                                    <div style={{ display : "block", marginTop : "50px", textAlign : "center" }}>
                                       
                                        <div>
                                            <button onClick={this.props.acceptCookieLaw} style={{ marginTop : "15px", padding : "12px 16px", borderRadius : "8px", border : "none", width : "192px", height : "52px", background : "#22C9C7", fontSize : "14px", textAlign : "center", textTransform : "uppercase", color : "white" }}>{t('cookie_accept')}</button>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display : "block", textAlign : "center", padding : "50px" }}>
                                        
                                        <div style={{ flex : "1" }}>
                                            <button onClick={this.props.acceptCookieLaw} style={{ padding : "12px 16px", borderRadius : "8px", border : "none", width : "192px", height : "52px", background : "#22C9C7", fontSize : "14px", textAlign : "center", textTransform : "uppercase", color : "white" }}>{t('cookie_accept')}</button>
                                        </div>
                                    </div>
                                }
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(Cookie)