import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';


import {
    translate
} from 'react-switch-lang';

export class Footer extends Component {

    closeTerms = () => {
        this.props.closeTermsAndConditions();
    }

    render() {

        const { t } = this.props;

        return (
            <nav className="navbar navbar-expand-lg footer">
                <img className="navbar-logo footer-logo" src={ require('../../images/tarjemle-logo-white.png') } />
                <ul class="navbar-nav custom-footer">
                    <li onClick={this.closeTerms} class="header-nav-item nav-item active">
                        <a class="footer-nav-link nav-link" href="#home">{t('nav-header.corporate')}</a>
                    </li>
                    <li onClick={this.closeTerms} class="header-nav-item nav-item">
                        <a class="footer-nav-link nav-link" href="#about-us">{t('nav-header.about-us')}</a>
                    </li>
                    <li onClick={this.closeTerms} class="header-nav-item nav-item">
                        <a class="footer-nav-link nav-link" href="#reviews">{t('nav-header.reviews')}</a>
                    </li>
                    <li class="header-nav-item nav-item">
                        <a class="footer-nav-link nav-link" href="javascript:void(0)" onClick={this.props.toggleTermsAndConditions}>{t('footer.terms-cond')}</a>
                    </li>
                </ul>
            </nav>
        )
    }

}

export default compose(
    translate
)(Footer)