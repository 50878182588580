import React, { Component } from 'react';

export class UserRating extends Component {

    render () {
        let ratings = [];
        const { rating } = this.props;

        let userRating = "";
        for(let i=0 ; i<rating; i++){
            ratings.push(<span key={i} style={{ marginRigth : "10px" }}><img src={ require('../../images/rating.png')}/></span>)
        }

        return (
            <div>{ratings}</div>
        );
        
        

    }

} 

export default UserRating