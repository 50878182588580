import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';

import TermsAndConditions from './TermsAndConditions';
import Header from './Header'
import Footer from './Footer'

import {
    getTermsAndConditions
} from '../../actions/termsAndConditionsAction';

import {
    setCurrentLanguage
} from '../../actions/appAction'

import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage
} from 'react-switch-lang';

import $ from 'jquery';

export class PageNotFound extends Component {

    constructor(){
        super();
        this.state = {
            isTermsAndConditionsActive : false
        }
    }

    componentDidMount(){
        if(localStorage.getItem('currentLanguage')===null){
            const navLanguage = window.navigator.language.substr(0, 2);
            if(navLanguage==="ar"){
              $('#mainStyle').attr("href","/css/ar-index.css")
            }else{
              $('#mainStyle').attr("href","/css/index.css")
            }    
      
            localStorage.setItem('currentLanguage',navLanguage)
            setLanguage(navLanguage);
            this.props.setCurrentLanguage(navLanguage);
        }else{
            const navLanguage = localStorage.getItem('currentLanguage')
      
            if(navLanguage==="ar"){
              $('#mainStyle').attr("href","/css/ar-index.css")
            }else{
              $('#mainStyle').attr("href","/css/index.css")
            }    
      
            localStorage.setItem('currentLanguage',navLanguage)
            setLanguage(navLanguage);
            this.props.setCurrentLanguage(navLanguage);
        }
        this.props.getTermsAndConditions();
    }

    componentDidUpdate(prevProps){
        if(this.props.currentLanguage!==prevProps.currentLanguage){
            if(localStorage.getItem('currentLanguage')===null){
                const navLanguage = window.navigator.language.substr(0, 2);
                if(navLanguage==="ar"){
                  $('#mainStyle').attr("href","/css/ar-index.css")
                }else{
                  $('#mainStyle').attr("href","/css/index.css")
                }    
          
                localStorage.setItem('currentLanguage',navLanguage)
                setLanguage(navLanguage);
                this.props.setCurrentLanguage(navLanguage);
            }else{
                const navLanguage = localStorage.getItem('currentLanguage')
          
                if(navLanguage==="ar"){
                  $('#mainStyle').attr("href","/css/ar-index.css")
                }else{
                  $('#mainStyle').attr("href","/css/index.css")
                }    
          
                localStorage.setItem('currentLanguage',navLanguage)
                setLanguage(navLanguage);
                this.props.setCurrentLanguage(navLanguage);
            }
            this.props.getTermsAndConditions();
        }
        
    }

    toggleTermsAndConditions = () => {
        this.setState({
            isTermsAndConditionsActive : !this.state.isTermsAndConditionsActive
        })
    }

    
    closeTermsAndConditions = () => {
        this.setState({
            isTermsAndConditionsActive : false
        })
    }

    render() {
        
        const { termsAndConditionsList, t, currentLanguage }  = this.props;
        const { isTermsAndConditionsActive } = this.state;

        return (
            <div className="landing-page">
                <Header closeTermsAndConditions={this.closeTermsAndConditions}/>
                <div className={isTermsAndConditionsActive ? "" : "landing-content"}>
                    {
                        isTermsAndConditionsActive ?
                        <TermsAndConditions currentLanguage={currentLanguage} t={t} toggleTermsAndConditions={this.toggleTermsAndConditions} termsAndConditionsList={termsAndConditionsList}/>
                        :
                        <div id="not-found">
                            <div className="not-found-heading">
                                <div>
                                    <p className="not-found-heading-text">404</p>
                                </div>
                                <div>
                                    <span className="not-found-heading-sub">{t('not_found_header')}</span>
                                    <Link className="btn-link not-found-heading-button" to="/">{t('go_to_homepage')}</Link>
                                </div>
                                <div className="not-found-small">
                                </div>
                            </div>
                        </div>

                    }
                </div>
                <Footer closeTermsAndConditions={this.closeTermsAndConditions} toggleTermsAndConditions={this.toggleTermsAndConditions}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        termsAndConditionsList : state.termsAndConditions.termsAndConditionsList,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        getTermsAndConditions,
        setCurrentLanguage
    })
)(PageNotFound)