export const getTermsAndConditions = () => ( dispatch, getState, { getFirestore }) => {

    dispatch({
        type : "GET_TERMS_AND_CONDITIONS"
    })

    const firestore = getFirestore();
    let appState = getState().app;

    if(appState.currentLanguage=="en"){
        
        firestore.collection('termsAndConditions').orderBy('order').get()
        .then( querySnapshots => {

            let termsAndConditions = [];

            querySnapshots.forEach( doc => {
                termsAndConditions.push(doc.data())
            })

            dispatch({
                type : "GET_TERMS_AND_CONDITIONS_SUCCESS",
                payload : {
                    data : termsAndConditions
                }
            })

        }).catch( error => {
            console.log(error)

            dispatch({
                type : "GET_TERMS_AND_CONDITIONS_ERROR"
            })
        })
    }else{

        firestore.collection('translations').doc('ar').collection('termsAndConditions').get()
        .then( querySnapshots => {

            let termsAndConditions = [];

            querySnapshots.forEach( doc => {
                termsAndConditions.push(doc.data())
            })

            dispatch({
                type : "GET_TERMS_AND_CONDITIONS_SUCCESS",
                payload : {
                    data : termsAndConditions
                }
            })

        }).catch( error => {
            console.log(error)

            dispatch({
                type : "GET_TERMS_AND_CONDITIONS_ERROR"
            })
        })
        
    }


}