import {
    ADD_MODAL,
    POP_MODAL,
    SET_HEADER,
    SET_DEVICE,
    SET_CURR_LANG
} from './types'

export const addModal = (data) => (dispatch) => {
    dispatch({
        type : ADD_MODAL,
        payload : {
            modalClass : data
        }
    })

}

export const popModal = (data) => (dispatch) => {
    dispatch({
        type : POP_MODAL,
        payload : {
            modalClass : data
        }
    })
}

export const setHeader = (data) => (dispatch) => {
    dispatch({
        type : SET_HEADER,
        payload : {
            isMobileHeader : data.isMobileHeader,
            textHeader : data.textHeader,
            backRedirect : data.link
        }
    })
}

export const setDevice = (isMobileView=false) => (dispatch) => {
    dispatch({
        type : SET_DEVICE,
        payload : {
            isMobileView : isMobileView
        }
    })
}

export const setCurrentLanguage = (currentLanguage="ar") => (dispatch) => {

    localStorage.setItem("currentLanguage",currentLanguage)
    
    dispatch({
        type : SET_CURR_LANG,
        payload : {
            currentLanguage : currentLanguage
        }
    })
}