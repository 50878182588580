import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';

import SpinnerModal from '../Modals/SpinnerModal'

import {
    translate
} from 'react-switch-lang';

export class ErrorPage extends Component {

    constructor(){
        super();
        this.state = {
            isProcessing : false,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){

    }

    windowClose = () => {
        window.open("/payment/error","_self").close();
    }

   
    render() {

        const { isProcessing } = this.state;
        const { t, currentLanguage, isMobileView } = this.props;

        const APP_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_APP_URL : process.env.REACT_APP_DEV_APP_URL
        const ADMIN_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_ADMIN_URL : process.env.REACT_APP_DEV_ADMIN_URL

        return (
            <div className="div-holder">
                <div style={{ textAlign : "center" }}>
                <img style={{ width : isMobileView ? "40%" : "20%", marginTop : "50px" }} src={ require('../../images/tarjemle-logo.png') }/>
                    {
                        isMobileView ? 
                        <div>
                            <div style={{ flex : "1", textAlign : "center", padding : "40px" }}>
                                <p style={{ fontWeight : "bold", color : "#414042", fontSize : "32px" }}>{t('payment_error_header')}</p>
                                <p style={{ fontWeight : "500", color : "#414042", fontSize : "18px" }}>{t('payment_error_content')}</p>
                                <p style={{ fontWeight : "bold", color : "#414042", fontSize : "24px" }}>{t('payment_status_closing')}</p>
                            </div>
                            <div style={{ flex: "1", marginTop : "50px" }}>
                                <img src={require('../../images/payment-error.png')} style={{ width : "280px", height : "212px" }} />
                            </div>
                        </div>   
                        :
                        <div style={{ display : "flex", marginTop : "100px" }}>
                            <div style={{ flex: "1" }}>
                                <img src={require('../../images/payment-error.png')} style={{ float : currentLanguage === "en" ? "right" : "left", marginRight : currentLanguage==="en" ? "50px" : "unset", marginLeft : currentLanguage==="ar" ? "50px" : "unset" , width : "280px", height : "212px" }} />
                            </div>
                            <div style={{ flex : "1" }} className="align-left">
                                <p style={{ fontWeight : "bold", color : "#414042", fontSize : "32px" }}>{t('payment_error_header')}</p>
                                <p style={{ fontWeight : "500", color : "#414042", fontSize : "18px", width : "50%" }}>{t('payment_error_content')}</p>
                                <p style={{ fontWeight : "bold", color : "#414042", fontSize : "24px" }}>{t('payment_status_closing')}</p>
                            </div>
                        </div>   
                    }  
                    

                </div>
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        currentLanguage : state.app.currentLanguage,
        isMobileView : state.app.isMobileView
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        
    })
)(ErrorPage)