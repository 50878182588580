import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import axios from 'axios'

import SpinnerModal from '../Modals/SpinnerModal'
import Message from '../Modals/Message'

import {
    setHeader,
    setDevice,
    setCurrentLanguage
  } from '../../actions/appAction'
  
  import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage
  } from 'react-switch-lang';

import { resendVerification } from '../../actions/authAction'

import $ from 'jquery'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            verificationStatusCode : 0,
            verificationMessage : "",
            verificationUserType : "",
            emailForVerification : "",
            isProcessing : false,
            isMessageModalActive : false,
            messageHeader : "",
            messageSubHeader : "",
            userUid : ""
        }
    }

    componentDidMount(){
        
        const { t } = this.props;
        
        let userEmail = this.props.match.params.email === undefined ? "" : this.props.match.params.email;

        if(this.props.location.search!==""){
            let splitSearch = this.props.location.search.split("=");
            
            let langIndex = splitSearch.findIndex( s => s==="?lang");
      
            if(langIndex!==-1){
              if(splitSearch[langIndex+1]==="ar" || splitSearch[langIndex+1]==="en"){
                if(splitSearch[langIndex+1]==="ar"){
                  $('#mainStyle').attr("href","/css/ar-index.css")
                }else{
                  $('#mainStyle').attr("href","/css/en-index.css")
                }    
          
                localStorage.setItem('currentLanguage',splitSearch[langIndex+1])
                setLanguage(splitSearch[langIndex+1]);
                this.props.setCurrentLanguage(splitSearch[langIndex+1]);
              }
            }
          }

        const FX_URL = process.env.REACT_APP_ENV=="production " ? 'https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/' : 'https://us-central1-tarjemle-dev-env.cloudfunctions.net/widgets/'

        if(userEmail){

            this.setState({
                emailForVerification : userEmail,
                isProcessing : true
            }, async () => {
                let requestBody = {
                    userEmail : userEmail
                }
    
                axios.post(`${FX_URL}verify_email`, requestBody)
                .then( result => {
                  
                    let message = "";
                    let user_type = "";
                    switch(result.data.code){
                        case 205:
                            message = t('verification_expired')
                            this.setState({
                                userUid : result.data.user_uid
                            })
                            break;
                        case 206:
                            message = t('verification_verified')
                            user_type = result.data.user_type
                            break;
                        case 400:
                            message = t('verification_malformed')
                            break;
                        case 404:
                            message = t('verification_account_not_found')
                            break;
                        case 405:
                            message = t('verification_not_found')
                            break;
                        case 500:
                            message = t('common_something_went_wrong')
                            break;
                        default:
                            message = t('verification_success')
                            user_type = result.data.user_type
                            break;
                    }
    
                    this.setState({
                        verificationMessage : message,
                        verificationStatusCode : result.data.code,
                        verificationUserType : user_type,
                        isProcessing : false
                    })
    
                })
                .catch( error => {
                    console.log(error)
                    this.setState({
                        verificationMessage : t('common_something_went_wrong'),
                        verificationStatusCode : 500,
                        isProcessing : false
                    })
                })
            })
        }
    }

    componentDidUpdate(prevProps){

    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        })
    }

    resendVerification = async () => {

        this.setState({
            isProcessing : true
        }, async () => {
            const { emailForVerification } = this.state;
            const FX_URL = process.env.REACT_APP_ENV=="production " ? 'https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/' : 'https://us-central1-tarjemle-dev-env.cloudfunctions.net/widgets/'


            let requestBody = {
                userUid : this.state.userUid,
                email : emailForVerification,
                lang : this.props.currentLanguage
            }
            
            axios.post(`${FX_URL}send_email_verification`, requestBody)
                .then( result => {
                    if(result.data.code===200){
                        const { t } = this.props;

                        this.setState({
                            isProcessing : false,
                            isMessageModalActive : true,
                            messageHeader : t('verification_resend_header'),
                            messageSubHeader : t('verification_resend_subheader'),
                        })
                    }
                })
                .catch( error => {
                    console.log(error)
                })
        })

    }

    render() {

        const { verificationMessage, verificationStatusCode, verificationUserType } = this.state;
        const { isProcessing } = this.state;
        const { isMessageModalActive, messageHeader, messageSubHeader } = this.state;
        const { t, currentLanguage } = this.props;

        const APP_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_APP_URL : process.env.REACT_APP_DEV_APP_URL
        const ADMIN_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_ADMIN_URL : process.env.REACT_APP_DEV_ADMIN_URL

        return (
            <div className="div-holder">
                <div style={{ textAlign : "center" }}>
                    <img style={{ width : "20%", marginTop : "50px" }} src={ require('../../images/tarjemle-logo.png') }/>
                    <p style={{ fontSize : "2rem", marginTop : "30px" }}>{ verificationMessage }</p>
                    {
                        (verificationStatusCode===205 || verificationStatusCode==405) && <a href="javascript:void(0)" onClick={this.resendVerification}><button style={{ marginTop : "15px", padding : "12px 16px", borderRadius : "8px", border : "none", width : "250px", height : "52px", background : "#22C9C7", fontSize : "14px", textAlign : "center", textTransform : "uppercase", color : "white" }}>{t('verification_to_resend')}</button></a>
                    }
                    {
                        (verificationStatusCode===206 || verificationStatusCode===200) && <div>{ (verificationUserType!=="" &&  verificationUserType=="translator" || verificationUserType=="customer")  ? <a href={APP_URL+`?lang=${currentLanguage}`}><button style={{ marginTop : "15px", padding : "12px 16px", borderRadius : "8px", border : "none", width : "250px", height : "52px", background : "#22C9C7", fontSize : "14px", textAlign : "center", textTransform : "uppercase", color : "white" }}>{t('verification_to_login')}</button></a> : <a href={ADMIN_URL}><button style={{ marginTop : "15px", padding : "12px 16px", borderRadius : "8px", border : "none", width : "192px", height : "52px", background : "#22C9C7", fontSize : "14px", textAlign : "center", textTransform : "uppercase", color : "white" }}>{t('verification_to_login')}</button></a> }</div>
                    }
                </div>
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isMessageModalActive &&
                    <Message
                        isOpened={isMessageModalActive}
                        closeMessageModal={this.closeMessageModal}
                        header={messageHeader}
                        subHeader={messageSubHeader}
                    />   
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        resendVerification,
        setCurrentLanguage
    })
)(Index)