import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../actions/appAction';

export class SpinnerModal extends Component{

    constructor(){
        super();
        this.state={
            modalZ : 1050
        }
    }

    componentDidMount(){

        const { isOpened } = this.props;
        if(isOpened){
            $('#spinnerModal').modal({backdrop:'static', keyboard:false});
            $('#spinnerModal').modal('show');
            if(this.props.addModal){
                this.props.addModal('spinner-modal')
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("sp-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="spinner-modal");
            if(modalInstance!==undefined){
                $('.sp-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#spinnerModal').modal('hide');
        if(this.props.popModal){
            this.props.popModal();
        }
        if($('.sp-bd')[0] !== undefined){
            $('.sp-bd')[0].remove();
        }
        $('body').removeClass('modal-open');
    }

    render(){
        const { modalZ } = this.state;

        return(
            <div style={{ zIndex : `${modalZ}` }} className="spinner-modal modal fade" id="spinnerModal" tabIndex="-1" role="dialog" aria-labelledby="spinnerModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div style={{ width: "10rem", height : "10rem" }} className="spinner-border text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(SpinnerModal)