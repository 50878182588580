const initState = {
    termsAndConditionsList : []
}

const termsAndConditionsReducer = ( state = initState, action ) => {

    switch(action.type){
        case "GET_TERMS_AND_CONDITIONS_SUCCESS":
            return {
                ...state,
                termsAndConditionsList : [...action.payload.data]
            }
        case "GET_TERMS_AND_CONDITIONS_ERROR":
            return {
                ...state,
                termsAndConditionsList : []
            }
        default:
            return state;
    }

}

export default termsAndConditionsReducer;