import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import termsAndConditionsReducer from './termsAndConditionsReducer';
import appReducer from './appReducer'

export default combineReducers({
    termsAndConditions : termsAndConditionsReducer,
    app : appReducer,
    firestore : firestoreReducer,
    firebase : firebaseReducer
})