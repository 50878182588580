import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
import MainIndex from './components/Main/Index';
import VerificationIndex from './components/Verification/Index'
import SuccessPage from './components/Payment/SuccessPage'
import ErrorPage from './components/Payment/ErrorPage'
import PageNotFound from './components/Main/PageNotFound'
import $ from 'jquery';
import { Helmet } from 'react-helmet';

import {
  setHeader,
  setDevice,
  setCurrentLanguage
} from './actions/appAction'

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
  getLanguage
} from 'react-switch-lang';

import en from './locales/en.json'
import ar from './locales/ar.json'

import axios from 'axios';

// Do this two lines only when setting up the application
setTranslations({ en, ar });

// If you want to remember selected language
setLanguageCookie();

const currentLang = getLanguage();

export class App extends Component {

  constructor(props) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  }

  componentDidMount() {
    
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
   
    if(this.props.location.search!==""){
      let splitSearch = this.props.location.search.split("=");
      
      let langIndex = splitSearch.findIndex( s => s==="?lang");

      if(langIndex!==-1){
        if(splitSearch[langIndex+1]==="ar" || splitSearch[langIndex+1]==="en"){
          if(splitSearch[langIndex+1]==="ar"){
            $('#mainStyle').attr("href","/css/ar-index.css")
          }else{
            $('#mainStyle').attr("href","/css/index.css")
          }    
    
          localStorage.setItem('currentLanguage',splitSearch[langIndex+1])
          setLanguage(splitSearch[langIndex+1]);
          this.props.setCurrentLanguage(splitSearch[langIndex+1]);
        }
      }
    }

    if(localStorage.getItem('currentLanguage')===null){

      const navLanguage = window.navigator.language.substr(0, 2);

      if(navLanguage==="ar"){
        $('#mainStyle').attr("href","/css/ar-index.css")
      }else{
        $('#mainStyle').attr("href","/css/index.css")
      }    

      localStorage.setItem('currentLanguage',navLanguage)
      setLanguage(navLanguage);
      this.props.setCurrentLanguage(navLanguage);
    }else{
      const navLanguage = localStorage.getItem('currentLanguage')

      if(navLanguage==="ar"){
        $('#mainStyle').attr("href","/css/ar-index.css")
      }else{
        $('#mainStyle').attr("href","/css/index.css")
      }    

      localStorage.setItem('currentLanguage',navLanguage)
      setLanguage(navLanguage);
      this.props.setCurrentLanguage(navLanguage);
    }
  }

  resize() {
    this.props.setDevice( window.innerWidth<1024 ? true : false)
  }

  render(){
    return (
      <div>
        <Helmet>
          <title>Tarjemle</title>
          <link rel="apple-touch-icon" href="/logo260.png" />
          <meta name="robots" content="index,follow" />
          <meta name="description" content="We are one of the most flexible language service provider in the industry. And we are online 24/7. With guaranteed quality, on time delivery and pay now or later option. we are always available and ready to adapt to every translation needs with our world’s best translators.
      As part of our translation services we assign a dedicated Account manager to overlook your tasks. Your dedicated AM will supervise your order resolving any issues that may arise and bridging communication between our translation teams and our clients. In essence, you can rest assured that your order is well attended to." />
        </Helmet>
        <Switch>
          <Route path="/" exact component={MainIndex}/>
          <Route path="/verify_email/:email" exact component={VerificationIndex} />
          <Route path="/payment/success" component={SuccessPage}/>
          <Route path="/payment/error" component={ErrorPage}/>
          <Route component={PageNotFound} />
        </Switch>
      </div>
    );
  }
  
}

const mapStateToProps = state => {
  return {
    auth : state.firebase.auth,
    userProfile : state.firebase.profile,
    isMobileView : state.app.isMobileView,
    currentLanguage : state.app.currentLanguage
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    setDevice,
    setCurrentLanguage
  })
)(App)