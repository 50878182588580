import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';

export class TermsAndConditions extends Component {

    convertNtoNewLine = (body) => {
       return body.replace(/\\n/g,"\n\n")
    }

    render() {
        
        const { termsAndConditionsList, isMobileView, t, currentLanguage }  = this.props;

        return (
            <div style={{ position : "relative", padding : isMobileView ? "90px 40px 40px 40px"  : "90px 15px 15px 15px" }}>
                <p style={{ textAlign : isMobileView ? "center" : currentLanguage === "ar" ? "right" : "left", fontSize : "2.5rem", color : "#414042", fontWeight : "500" }}>{t('footer.terms-cond')}</p>
                <p style={{ position : "absolute", right : currentLanguage==="ar"? "unset" : "20px", left : currentLanguage ==="ar" ? "20px" : "unset", top : "90px", cursor : "pointer" }} onClick={this.props.toggleTermsAndConditions}>X</p>
                {
                    termsAndConditionsList.map( (data, i) => (
                        <div key={i} style={{ textAlign : currentLanguage==="ar" ? "right" : "left" }}>
                            <p style={{ marginBottom : "8px", fontSize :"20px", color : "#000000", fontWeight : "600" }}>{data.title}</p>
                            <p style={{  whiteSpace : "pre-line", lineHeight : "28px", fontSize :"16px", color : "#464646", fontWeight : "500" }}>{this.convertNtoNewLine(data.body)}</p>
                        </div>
                    ))
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps)
)(TermsAndConditions)