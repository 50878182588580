import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CustomerReview from './CustomerReview';
import TermsAndConditions from './TermsAndConditions';

import {
    translate,
} from 'react-switch-lang';

export class Content extends Component {

    constructor(){
        super();
        this.state = {
            reviews : [
                {
                    user : "Tomothy Fox",
                    rating : 5,
                    user_photo : require('../../images/user1.png')
                },
                {
                    user : "Marvin Jones",
                    rating : 5,
                    user_photo : require('../../images/user2.png')
                },
                {
                    user : "Theresa Fisher",
                    rating : 5,
                    user_photo : require('../../images/user3.png')
                }
            ]
        }
    }

    componentDidMount(){

        const { reviews } = this.state;
        const { t } = this.props;

        reviews.map( (data, i) => {
            data.content = t(`testimonials.user_${(i+1)}.content`);
            data.user_type = t(`testimonials.user_${(i+1)}.type`);
        })
        this.setState({
            reviews : reviews
        })

    }

    componentDidUpdate(prevProps){
        if(this.props.currentLanguage!==prevProps.currentLanguage){
            const { reviews } = this.state;
            const { t } = this.props;
    
            reviews.map( (data, i) => {
                data.content = t(`testimonials.user_${(i+1)}.content`);
                data.user_type = t(`testimonials.user_${(i+1)}.type`);
            })

            this.setState({
                reviews : reviews
            }, () => {
                reviews.map( (data, i) => {
                    data.content = t(`testimonials.user_${(i+1)}.content`);
                    data.user_type = t(`testimonials.user_${(i+1)}.type`);
                })

                this.setState({
                    reviews : reviews
                })
            })
        }
    }

    render() {

        const { reviews }  = this.state;
        const { isTermsAndConditionsActive, termsAndConditionsList } = this.props;
        const { isMobileView } = this.props;

        const { t, currentLanguage } = this.props;

        const APP_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_APP_URL : process.env.REACT_APP_DEV_APP_URL
        const ADMIN_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_ADMIN_URL : process.env.REACT_APP_DEV_ADMIN_URL

        if(isTermsAndConditionsActive){
            return <TermsAndConditions t={t} currentLanguage={currentLanguage} toggleTermsAndConditions={this.props.toggleTermsAndConditions} termsAndConditionsList={termsAndConditionsList}/>
        }else{
            return (
                <div className="landing-content">
                    <div id="home">
                        <div className="home-heading">
                            <div>
                                <p className="home-heading-text" style={{ width : isMobileView ? "100%" : "50%" }}>{t('home.header')}</p>
                            </div>
                            <div className="home-small">
                            </div>
                            <div>
                                <span className="home-heading-sub">{t('home.sub-header')}</span>
                                <a className="btn-link home-heading-button" href={APP_URL+`?lang=${currentLanguage}`}>{t('home.btn-order')}</a>
                            </div>
                        </div>
                    </div>
                    <div id="about-us">
                        <div className="row about-us-content-rev">
                            <div className="col about-us-text">
                                <p className="about-us-text-header">{t('about-us.header-one')} {t('about-us.header-one-sub')}</p>
                                <span className="about-us-text-sub">{t('about-us.content-one')}</span>
                            </div>
                            <div className="col about-us-img">
                                <img src={require('../../images/about-us-books.png')}/>
                            </div>
                        </div>
                        <div className="row about-us-content">
                            <div className="col about-us-img">
                                <img src={require('../../images/about-us-work.png')}/>
                            </div>
                            <div className="col about-us-text">
                                <p className="about-us-text-header">{t('about-us.header-two')}</p>
                                <span className="about-us-text-sub">{t('about-us.content-two')}</span>
                            </div>
                        </div>
                    </div>
                    <div id="why-us">
                        <img className="arrow-1" src={require('../../images/arrow-1.png')}/>
                        <p className="why-us-header">{t('why-us.header')}</p>
                        <div className="row why-us-content">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <img className="why-us-img-header" src={require('../../images/icon-clock.png')}/>
                                <p className="why-us-content-header">{t('why-us.col-one-header')}</p>
                                <p className="why-us-content-text">{t('why-us.col-one-content')}</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <img className="why-us-img-header" src={require('../../images/icon-hq.png')}/>
                                <p className="why-us-content-header">{t('why-us.col-two-header')}</p>
                                <p className="why-us-content-text">{t('why-us.col-two-content')}</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <img className="why-us-img-header" src={require('../../images/icon-payment.png')}/>
                                <p className="why-us-content-header">{t('why-us.col-three-header')}</p>
                                <p className="why-us-content-text">{t('why-us.col-three-content')}</p>
                            </div>
                        </div>
                    </div>
                    <div id="user-stat">
                        <div className="row user-count-panel">
                            <div className="mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="user-count">
                                    <p className="count-text-customer-bg">5&nbsp;213</p>
                                    <p className="count-text-customer">5&nbsp;213</p>
                                    <p className="count-desc">{t('user-stat.customer-header')}</p>
                                    <p className="count-descsub">{t('user-stat.customer-sub-header')}</p>
                                </div>
                            </div>
                            <div className="mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="user-count">
                                    <p className="count-text-translator-bg">3&nbsp;450</p>
                                    <p className="count-text-translator">3&nbsp;450</p>
                                    <p className="count-desc">{t('user-stat.translator-header')}</p>
                                    <p className="count-descsub">{t('user-stat.translator-sub-header')}</p>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div id="work-types">
                        <p className="work-types-header">{t('work-types.header')}</p>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                <div style={{ margin : "0 auto", border : "1px solid #03A5A4", borderRadius : "8px", padding : "40px 32px", height : "280px", width : "280px" }}>
                                    <p style={{ textAlign : "center", fontSize : "32px"}}>{t('work-types.essay')}</p>
                                    <img style={{ height : "170px", display : 'block', margin : '0 auto' }} src={require('../../images/work-essay.png')}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                <div style={{ margin : "0 auto", border : "1px solid #03A5A4", borderRadius : "8px", padding : "40px 32px", height : "280px", width : "280px" }}>
                                    <p style={{ textAlign : "center", fontSize : "32px"}}>{t('work-types.dissertation')}</p>
                                    <img style={{ width : '190px', display : "block", marginTop: "80px", marginLeft : 'auto', marginRight : 'auto' }} src={require('../../images/work-dissertation.png')}/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                                <div style={{ margin : "0 auto", border : "1px solid #03A5A4", borderRadius : "8px", padding : "40px 32px", height : "280px", width : "280px" }}>
                                    <p style={{ textAlign : "center", fontSize : "32px"}}>{t('work-types.presentation')}</p>
                                    <img style={{ width : '210px', display : "block", marginTop: "50px", marginLeft : 'auto', marginRight : 'auto' }} src={require('../../images/work-presentation.png')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="register"> 
                        <img className="arrow-3" src={require('../../images/arrow-3.png')}/>
                        <p className="register-header" style={{ marginBottom : "0" }}>{t('register.header')}</p>
                        <p className="register-header">{t('register.sub-header')}</p>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6" style={{ textAlign: "right" }} >
                                <img className="register-img" src={ require('../../images/register.png')}/>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 register-text-div">
                                <p className="register-text">{t('register.content')}</p>
                                <a className="btn-link register-button" href={`${APP_URL}/signup#translator?`+`lang=${currentLanguage}`}>{t('register.btn-text')}</a>
                            </div>
                        </div>
                    </div>
                    <div id="reviews">
                        <img className="arrow-4" src={require('../../images/arrow-2.png')}/>
                        <p className="review-header">{t('reviews.header')}</p>
                        {
                            isMobileView ?
                            <div id="review-carousel" className="carousel slide carousel-review" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#review-carousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#review-carousel" data-slide-to="1"></li>
                                    <li data-target="#review-carousel" data-slide-to="2"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <CustomerReview review={reviews[0]}/>
                                    </div>
                                    <div className="carousel-item">
                                        <CustomerReview review={reviews[1]}/>
                                    </div>
                                    <div className="carousel-item">
                                        <CustomerReview review={reviews[2]}/>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="reviews">
                                {
                                    reviews.map( (data, i) => (
                                        <CustomerReview key={i} review={data}/>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    <div id="ending">
                        <div>
                            <p className="end-heading-text">{t('ending.header')}</p>
                        </div>
                        <div className="ending-small">
                        </div>
                        <div>
                            <a href={APP_URL+`?lang=${currentLanguage}`} className="btn-link end-heading-button">{t('ending.btn-text')}</a>
                        </div>
                    </div>
                    <span className="version">v1.0.2</span>
                    {/* <div id="about-us" className="landing-about-us">
                        <div className="row about-us-content-rev">
                            <div className="col about-us-text">
                                <p className="about-us-text-header">Are you in need translation services? <br/> We're here to help.</p>
                                <span className="about-us-text-sub">We are one of the most flexible language service providers in the industry. We are adaptive
    and online 24/7, which means we are always available for your translation needs. Have your
    paper work translated with our human translation services.</span>
                            </div>
                            <div className="col about-us-img">
                                <img src={require('../../images/about-us-books.png')}/>
                            </div>
                        </div>
                        <div className="row about-us-content">
                            <div className="col about-us-img">
                                <img src={require('../../images/about-us-work.png')}/>
                            </div>
                            <div className="col about-us-text">
                                <p className="about-us-text-header">We focus on your business and offer quality translation services.</p>
                                <span className="about-us-text-sub">As part of our translation services we assign a dedicated account manager to overlook your
    requested tasks. Your dedicated account manager will supervise your order, resolving any
    issues that may arise, bridging communication between our translation teams and our clients. In
    essence, you can rest assured that your order is well attended to.</span>
                            </div>
                            
                        </div>
                    </div>
                    <div id="pricing" className="landing-pricing">
                        <img className="arrow-1" src={require('../../images/arrow-1.png')}/>
                        <p className="pricing-header">Why Choose Us</p>
                        <div className="row pricing-content">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <img className="pricing-img-header" src={require('../../images/icon-clock.png')}/>
                                <p className="pricing-content-header">On-Time Delivery </p>
                                <p className="pricing-content-text">We offer the best performance levels, with our optimized workflow we guarantee that over 95%
    of deliveries will be made on time. In the unlikely event that we miss a deadline, we will refund
    the translation up to its full cost.</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <img className="pricing-img-header" src={require('../../images/icon-hq.png')}/>
                                <p className="pricing-content-header">Guaranted Quality </p>
                                <p className="pricing-content-text">We support the world’s best translators with advanced quality assurance processes. That’s not
    all: we provide a free comprehensive review if you are unsatisfied.</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <img className="pricing-img-header" src={require('../../images/icon-payment.png')}/>
                                <p className="pricing-content-header">Pay now or later </p>
                                <p className="pricing-content-text">We genuinely trust our clients, which is why we provide you with the option of paying only 50%
    of your order. You can choose to pay via credit card, bank transfer or Paypal.</p>
                            </div>
                        </div>
                        <div className="row user-count-panel">
                            <div className="mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="user-count">
                                    <p className="count-text-customer-bg">5&nbsp;213</p>
                                    <p className="count-text-customer">5&nbsp;213</p>
                                    <p className="count-desc">Customers on the portal</p>
                                    <p className="count-descsub">An average of 100 new customers per month</p>
                                </div>
                            </div>
                            <div className="mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="user-count">
                                    <p className="count-text-translator-bg">3&nbsp;450</p>
                                    <p className="count-text-translator">3&nbsp;450</p>
                                    <p className="count-desc">Word translator</p>
                                    <p className="count-descsub">Every month An average of 100 new certified translators registered to our portal</p>
                                </div>
                            </div>
                        </div>
                        <img className="arrow-2" src={require('../../images/arrow-2.png')}/>
                        <p style={{ marginTop: "200px" }} className="pricing-header">Three types of work</p>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3"><img style={{ width: "100%", minHeight : "350px" }} src={require('../../images/work-dissertation.png')}/></div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3"><img style={{ width: "100%", minHeight : "350px" }} src={require('../../images/work-essay.png')}/></div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3"><img style={{ width: "100%", minHeight : "350px" }} src={require('../../images/work-presentation.png')}/></div>
                        </div>
                    </div>
                    <div id="register" className="landing-register">
                        <img className="arrow-3" src={require('../../images/arrow-3.png')}/>
                        <p className="register-header">Are you a Word translator? <br/> Let's work together</p>
                        <div className="row">
                            <div className="col" style={{ textAlign: "right"}} >
                                <img className="register-img" src={ require('../../images/register.png')}/>
                            </div>
                            <div className="col" style={{ paddingTop: "120px" }}>
                                <p className="register-text">Upload your resume to see <br/>if you qualify as a translator, once approved you can start
    immediately.</p>
                                <a className="btn-link register-button" href="https://app.tarjemle.com/signup#translator">REGISTER PROFILE</a>
                            </div>
                        </div>
                    </div>
                    <div id="reviews" className="landing-reviews">
                        <img className="arrow-4" src={require('../../images/arrow-2.png')}/>
                        <p className="review-header">Reviews from happy customer</p>
                        <div className="reviews">
                            {
                                reviews.map( (data, i) => (
                                    <CustomerReview key={i} review={data}/>
                                ))
                            }
                        </div>
                    </div>
                    <div id="end" className="landing-end">
                        <div className="end-heading">
                            <p className="end-heading-text">Need important <br/> documents translated ?</p>
                            <a href="https://app.tarjemle.com" className="btn-link end-heading-button">ORDER SERVICE NOW</a>
                        </div>
                    </div>
                    <span className="version">v1.15.0</span> */}
                </div>
            )
        }
    }

}

export default compose(
    translate
)(Content)