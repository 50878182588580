import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';

import Content from './Content'
import Header from './Header'
import Footer from './Footer'

import {
    getTermsAndConditions
} from '../../actions/termsAndConditionsAction';

import {
    setCurrentLanguage
} from '../../actions/appAction'

import CookieModal, { Cookie } from '../Modals/Cookie'

import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage
} from 'react-switch-lang';

import $ from 'jquery';

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isTermsAndConditionsActive : false,
            isCookieModalActive : false
        }
    }

    componentDidMount(){

        if(localStorage.getItem("cookie_law_accepted")===null || localStorage.getItem("cookie_law_accepted")==="false"){
            this.setState({
                isCookieModalActive : true
            })
        }

        if(localStorage.getItem('currentLanguage')===null){
            const navLanguage = window.navigator.language.substr(0, 2);
            if(navLanguage==="ar"){
              $('#mainStyle').attr("href","/css/ar-index.css")
            }else{
              $('#mainStyle').attr("href","/css/index.css")
            }    
      
            localStorage.setItem('currentLanguage',navLanguage)
            setLanguage(navLanguage);
            this.props.setCurrentLanguage(navLanguage);
        }else{
            const navLanguage = localStorage.getItem('currentLanguage')
      
            if(navLanguage==="ar"){
              $('#mainStyle').attr("href","/css/ar-index.css")
            }else{
              $('#mainStyle').attr("href","/css/index.css")
            }    
      
            localStorage.setItem('currentLanguage',navLanguage)
            setLanguage(navLanguage);
            this.props.setCurrentLanguage(navLanguage);
        }

        this.props.getTermsAndConditions();

        if(this.props.history.location.hash==="#terms-and-conditions"){
            this.setState({
                isTermsAndConditionsActive : true
            }, () => {
                $('html')[0].scrollTop = 0
            })
        }

    }

    componentDidUpdate(prevProps){
        if(this.props.currentLanguage!==prevProps.currentLanguage){
            if(localStorage.getItem('currentLanguage')===null){
                const navLanguage = window.navigator.language.substr(0, 2);
                if(navLanguage==="ar"){
                  $('#mainStyle').attr("href","/css/ar-index.css")
                }else{
                  $('#mainStyle').attr("href","/css/index.css")
                }    
          
                localStorage.setItem('currentLanguage',navLanguage)
                setLanguage(navLanguage);
                this.props.setCurrentLanguage(navLanguage);
            }else{
                const navLanguage = localStorage.getItem('currentLanguage')
          
                if(navLanguage==="ar"){
                  $('#mainStyle').attr("href","/css/ar-index.css")
                }else{
                  $('#mainStyle').attr("href","/css/index.css")
                }    
          
                localStorage.setItem('currentLanguage',navLanguage)
                setLanguage(navLanguage);
                this.props.setCurrentLanguage(navLanguage);
            }
            this.props.getTermsAndConditions();
        }
        
        if(this.props.history.location.hash!==prevProps.history.location.hash){
            if(this.props.history.location.hash==="#terms-and-conditions"){
                this.setState({
                    isTermsAndConditionsActive : true
                }, () => {
                    $('html')[0].scrollTop = 0
                })
            }
        }
    }

    toggleTermsAndConditions = () => {
        this.setState({
            isTermsAndConditionsActive : !this.state.isTermsAndConditionsActive
        }, () => {

            if(this.state.isTermsAndConditionsActive){
                window.location.hash = "#terms-and-conditions"
                $('html')[0].scrollTop = 0
            }else{
                window.location.hash = ""
            }

        })
    }

    closeTermsAndConditions = () => {
        this.setState({
            isTermsAndConditionsActive : false
        })
        window.location.hash = ""
    }

    acceptCookieLaw = () => {
        this.setState({
            isCookieModalActive : false
        }, () => {
            localStorage.setItem("cookie_law_accepted", "true")
        })
    }

    closeCookieModal = () => {
        this.setState({
            isCookieModalActive : false
        })
    }

    render() {

        const { termsAndConditionsList, isMobileView, currentLanguage, t } = this.props;
        const { isTermsAndConditionsActive, isCookieModalActive } = this.state;

        return (
            <div>
                <div className="landing-page">
                    <Header closeTermsAndConditions={this.closeTermsAndConditions}/>
                    <Content currentLanguage={currentLanguage} toggleTermsAndConditions={this.toggleTermsAndConditions} isMobileView={isMobileView} termsAndConditionsList={termsAndConditionsList} isTermsAndConditionsActive={isTermsAndConditionsActive}/>
                    <Footer closeTermsAndConditions={this.closeTermsAndConditions} toggleTermsAndConditions={this.toggleTermsAndConditions}/>
                </div>
                {
                    isCookieModalActive &&
                    <CookieModal
                        acceptCookieLaw={this.acceptCookieLaw}
                        closeCookieModal={this.closeCookieModal}
                        isOpened={isCookieModalActive}
                        currentLanguage={currentLanguage}
                        isMobileView={isMobileView}
                        t={t}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        termsAndConditionsList : state.termsAndConditions.termsAndConditionsList,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        getTermsAndConditions,
        setCurrentLanguage
    })
)(Index)