import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../actions/appAction'

export class Message extends Component {
    
    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#messageModal').modal({backdrop:'static', keyboard:false});
            $('#messageModal').modal('show');
            if(this.props.addModal){
                this.props.addModal('message-modal')
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("m-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="message-modal");
            if(modalInstance!==undefined){
                $('.m-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#messageModal').modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }

    render(){

        const { opened, header, subHeader, link } = this.props;
        const { modalZ } = this.state;

        return (
            <div style={{ zIndex : `${modalZ}` }} className="message-modal modal fade" id="messageModal" tabIndex="-1" role="dialog" aria-labelledby="messageModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className="text-header">{ header }</p>
                            <p className="text-sub-header">{ subHeader }</p>
                            <a style={{ cursor : "pointer" }} onClick={this.props.closeMessageModal} className="btn-link ok">OK</a>
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(Message)