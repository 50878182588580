import {
    VERIFY_EMAIL_ADDRESS,
    VERIFY_EMAIL_ADDRESS_SUCCESS,
    VERIFY_EMAIL_ADDRESS_ERROR
} from './types'

import moment from 'moment'

export const verifyEmail = (data) => async (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : VERIFY_EMAIL_ADDRESS
    })

}

export const resendVerification = (data) => async ( dispatch, getState, { getFirebase, getFirestore }) => {

    const firebase = getFirebase();
    let appState = getState().app;

    let requestBody = {
        userUid : data.uid,
        email : data.emailAddress,
        lang : appState.currentLanguage
    }
        
    var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');

    sendEmailVerification(requestBody).then( (result) => {
    }).catch( error => {
        console.log(error);
    })

}